<ids-container language="en" locale="en-US" padding="8" hidden background-color="--ids-homepage-color-background-default" animated>
  <ids-theme-switcher mode="light"></ids-theme-switcher>

  <ng-container *ngFor="let category of categories">
    <ids-layout-grid auto-fit="true" gap="md" padding-x="md">
      <ids-layout-grid-cell>
        <ids-text font-size="24" type="h2">{{category.name}}</ids-text>
      </ids-layout-grid-cell>
    </ids-layout-grid>
    <ids-layout-grid auto-fit="true" gap="md" padding-x="md">
      <ids-layout-grid-cell>
        <ids-block-grid>
          <ng-container *ngFor="let route of routes">
            <ids-block-grid-item *ngIf="route.data.category === category.name">
              <ids-card [attr.actionable]="'true'" routerLink="{{route.path}}" href="{{route.path}}" target="_self" [attr.height]="100">
                <div slot="card-content">
                  <ids-text font-size="16" [attr.type]="'h2'" font-weight="bold">{{route.data.title}}</ids-text>
                  <ids-text font-size="16" type="p">{{route.data.description}}</ids-text>
                </div>
              </ids-card>
            </ids-block-grid-item>
          </ng-container>
        </ids-block-grid>
      </ids-layout-grid-cell>
    </ids-layout-grid>
  </ng-container>
</ids-container>
